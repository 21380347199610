<template>
  <v-container>
    <v-card elevation="0">
      <v-row class="justify-center">
        <v-col cols="12" :class="resetInputs ? '' : 'col-md-9'">
          <Alert v-if="!isExternalPage" class="mt-0" dense color="info">
            <v-row>
              <v-col cols="9">
                <span v-html="$t('userInformations.alertTagInfoText')" />
              </v-col>
              <v-col cols="3" class="d-flex justify-end">
                <v-play-video-button
                  small
                  justify_btn="end"
                  color="info"
                  :title="$t('common.howToUseInformation')"
                  id_youtube_video="6uAXHh60D-M"
                />
              </v-col>
            </v-row>
          </Alert>
          <v-expansion-panels v-model="panel" multiple :flat="resetInputs">
            <v-expansion-panel>
              <v-expansion-panel-header class="font-weight-medium text-h6">
                {{ $t("userInformations.personalInformation") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Tags NÃO editáveis -->
                <v-row v-for="(tag, i) in disabledTagsNames" :key="i">
                  <v-col cols="12" sm="4" lg="4" align-self="center">
                    <v-card-text class="font-weight-medium body-2 py-0 pl-2">
                      {{ $t(userDisabledTags[tag].translatePath) }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="info"
                            small
                            v-bind="attrs"
                            v-on="on"
                            v-text="'mdi-help-circle-outline'"
                          />
                        </template>
                        <span>{{
                          $t("userInformations.editOnlyInWorkspace")
                        }}</span>
                      </v-tooltip>
                    </v-card-text>
                    <v-card-text class="body-2 py-0 pl-2">
                      {{ userDisabledTags[tag].label }}
                    </v-card-text>
                  </v-col>

                  <v-col
                    class="text-left"
                    align-self="start"
                    cols="12"
                    sm="7"
                    lg="8"
                  >
                    <div v-if="tag == 'photo'">
                      <v-avatar size="60" class="mb-6 mt-0">
                        <img v-if="!photo" src="@/assets/user-default.png" />
                        <img v-else :src="photo" />
                      </v-avatar>
                    </div>
                    <div v-else>
                      <v-card-text
                        class="body-2 py-0 pl-0 caption grey--text text--darken-2 mb-0"
                        >{{ userDisabledTags[tag].labelGoogle }}</v-card-text
                      >
                      <v-text-field
                        color="grey"
                        :lable="userDisabledTags[tag].labelGoogle"
                        v-model="tagsEdit[tag]"
                        class="pt-0 pr-2 my-0 body-2"
                        hide-details="auto"
                        disabled
                      />
                    </div>
                  </v-col>
                </v-row>

                <!-- TAGS EDITÀVEIS CONTATO -->
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row v-for="tag in contactTags" :key="tag">
                    <v-col cols="12" sm="4" lg="4" align-self="center">
                      <v-card-text
                        class="font-weight-medium body-2 py-0 pl-2 d-flex align-center"
                      >
                        {{ $t(userEditableTags[tag].translatePath) }}
                      </v-card-text>
                      <v-card-text class="body-2 py-0 pl-2">{{
                        userEditableTags[tag].label
                      }}</v-card-text>
                    </v-col>

                    <v-col
                      class="text-left"
                      align-self="start"
                      cols="12"
                      sm="7"
                      lg="8"
                    >
                      <v-text-field
                        color="accent"
                        v-model="tagsEdit[tag]"
                        class="pt-0 pr-2 my-0 body-2"
                        autocomplete="off"
                        hide-details="auto"
                        :label="
                          userEditableTags[tag].labelGoogle ||
                          $t(userEditableTags[tag].translatePath) ||
                          userEditableTags[tag].keyName
                        "
                        :rules="[rules.maxPhone]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
              <v-divider v-if="resetInputs" class="mt-5 mb-3" />
            </v-expansion-panel>

            <v-expansion-panel>
              <!-- TAGS EDITÀVEIS FUNCIONÁRIO -->
              <v-expansion-panel-header class="font-weight-medium text-h6">
                {{ $t("userInformations.employeelInformation") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row v-for="tag in employeeTags" :key="tag">
                    <v-col cols="12" sm="4" lg="4" align-self="center">
                      <v-card-text
                        class="font-weight-medium body-2 py-0 pl-2 d-flex align-center"
                      >
                        {{ $t(userEditableTags[tag].translatePath) }}
                        <v-tooltip
                          bottom
                          v-if="
                            userEditableTags[tag].label ===
                            '[*USER_SIGN_PHOTO*]'
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="info"
                              small
                              v-bind="attrs"
                              v-on="on"
                              v-text="'mdi-help-circle-outline'"
                            />
                          </template>
                          <span>{{
                            $t("userInformations.photoTooltipText")
                          }}</span>
                        </v-tooltip>
                      </v-card-text>
                      <v-card-text class="body-2 py-0 pl-2">{{
                        userEditableTags[tag].label
                      }}</v-card-text>
                    </v-col>

                    <v-col
                      class="text-left"
                      align-self="start"
                      cols="12"
                      sm="7"
                      lg="8"
                    >
                      <div
                        v-if="
                          userEditableTags[tag].label === '[*USER_SIGN_PHOTO*]'
                        "
                      >
                        <v-avatar
                          v-if="!hasAcceptedDriveScopes || isCompanyMicrosoft"
                          size="60"
                          class="mb-4"
                          v-ripple
                        >
                          <img
                            v-if="!tagsEdit[tag]"
                            :src="getPreviewTagImage"
                          />
                          <img v-else :src="tagsEdit[tag]" />
                        </v-avatar>

                        <div v-else class="d-flex align-center mb-4">
                          <v-badge
                            bordered
                            bottom
                            color="accent"
                            offset-x="15"
                            offset-y="40"
                            icon="mdi-pencil"
                          >
                            <v-avatar
                              @click="openUploadImageDialog(tag, tagsEdit[tag])"
                              size="60"
                              class="mb-6 avatar"
                              v-ripple
                            >
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <img
                                    v-if="!tagsEdit[tag]"
                                    v-bind="attrs"
                                    v-on="on"
                                    :src="getPreviewTagImage"
                                  />
                                  <img
                                    v-else
                                    :src="tagsEdit[tag]"
                                    v-bind="attrs"
                                    v-on="on"
                                  />
                                </template>
                                <span>{{
                                  $t("userInformations.addImage")
                                }}</span>
                              </v-tooltip>
                            </v-avatar>
                          </v-badge>

                          <div
                            class="ml-6 d-flex flex-column"
                            v-if="!isCompanyMicrosoft"
                          >
                            <v-btn
                              elevation="0"
                              outlined
                              text
                              small
                              class="text-none mb-4"
                              @click="openUploadImageDialog(tag, tagsEdit[tag])"
                            >
                              <v-icon left dark>
                                mdi-cloud-upload-outline
                              </v-icon>
                              {{ $t("userInformations.upload") }}
                            </v-btn>
                          </div>
                        </div>

                        <v-text-field
                          color="accent"
                          v-model="tagsEdit[tag]"
                          @input="updateURL(tag)"
                          class="pt-0 pr-2 my-0 body-2"
                          autocomplete="off"
                          clearable
                          :label="userEditableTags[tag].label"
                          @blur="checkPhotoLink"
                          :error-messages="photoLinkError"
                        ></v-text-field>
                      </div>

                      <v-text-field
                        v-else
                        color="accent"
                        v-model="tagsEdit[tag]"
                        class="pt-0 pr-2 my-0 body-2"
                        autocomplete="off"
                        :label="
                          userEditableTags[tag].labelGoogle ||
                          $t(userEditableTags[tag].translatePath) ||
                          userEditableTags[tag].keyName
                        "
                        :rules="[rules.maxUserInfo]"
                        :persistent-hint="
                          userEditableTags[tag]?.hint ? true : false
                        "
                        :hint="
                          userEditableTags[tag]?.hint &&
                          $t(userEditableTags[tag].translateHintPath)
                        "
                      >
                        <template v-slot:message="{ message }">
                          <div
                            style="color: #1948a3"
                            class="d-flex align-center"
                            v-if="
                              userEditableTags[tag].label ==
                              '[*MEETING_SCHEDULE_LINK*]'
                            "
                          >
                            <v-icon small class="mr-1" color="#2196f3"
                              >mdi-information</v-icon
                            >
                            <span v-html="message" />
                          </div>
                          <span v-else v-html="message" />
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
              <v-divider v-if="resetInputs" class="mt-5 mb-3" />
            </v-expansion-panel>

            <v-expansion-panel>
              <!-- TAGS CUSTOMIZÁVEIS DE FUNCIONÁRIO -->
              <v-expansion-panel-header class="font-weight-medium text-h6">
                {{ $t("userInformations.customInformations") }}
                <v-spacer />
                <div class="d-flex justify-end">
                  <v-tooltip bottom v-if="!isExternalPage">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="tagLimitExceeded && on">
                        <v-btn
                          :loading="tagLoading"
                          :disabled="tagLoading || tagLimitExceeded"
                          small
                          color="accent"
                          class="ma-2 white--text text-none"
                          @click.stop="showNewTagDialog = true"
                        >
                          <v-icon left dark> mdi-plus </v-icon>
                          {{ $t("userInformations.addNewTag") }}
                        </v-btn>
                      </div>
                    </template>
                    <span
                      >O limite de informações personalizadas foi excedido</span
                    >
                  </v-tooltip>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row v-for="tag in userCustomTags" :key="tag">
                    <v-col cols="12" sm="4" lg="4" align-self="center">
                      <v-card-text
                        class="font-weight-medium body-2 py-0 pl-2 d-flex align-center"
                      >
                        {{ $t(userEditableTags[tag].translatePath) }}
                        <v-tooltip
                          v-if="
                            $t(userEditableTags[tag].infoTranslatePath) ||
                            userEditableTags[tag].info
                          "
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="info"
                              small
                              v-bind="attrs"
                              v-on="on"
                              v-text="'mdi-help-circle-outline'"
                            />
                          </template>
                          <span>{{
                            $t(userEditableTags[tag].infoTranslatePath)
                          }}</span>
                        </v-tooltip>
                      </v-card-text>
                      <v-card-text class="body-2 py-0 pl-2">{{
                        userEditableTags[tag].label
                      }}</v-card-text>
                    </v-col>

                    <v-col
                      class="text-left"
                      align-self="start"
                      cols="12"
                      sm="7"
                      lg="8"
                    >
                      <v-text-field
                        :data-testid="`${tag}`"
                        color="accent"
                        v-model="tagsEdit[tag]"
                        hide-details="auto"
                        @input="updateURL(tag)"
                        class="pt-0 pr-2 my-0 body-2"
                        autocomplete="off"
                        :placeholder="
                          $t(userEditableTags[tag].translatePlaceholderPath)
                        "
                        :label="
                          $t(userEditableTags[tag].translatePath) ||
                          userEditableTags[tag].keyName
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(tag, i) in customTagsEdit"
                    :key="i"
                    class="mt-4"
                  >
                    <v-col
                      cols="12"
                      sm="4"
                      lg="4"
                      align-self="start"
                      class="px-0 mx-0 d-flex align-end"
                    >
                      <div>
                        <v-card-text class="font-weight-medium body-2 py-0 ml-1"
                          >{{ tag.name }}
                        </v-card-text>
                        <v-card-text
                          class="grey--text text--darken-2 body-2 py-0 ml-1"
                          :style="
                            $vuetify.breakpoint.mdAndDown &&
                            $vuetify.breakpoint.smAndUp
                              ? 'inline-size: 183px'
                              : ''
                          "
                          >{{ tag.tag }}</v-card-text
                        >
                      </div>
                      <v-tooltip top v-if="!isExternalPage">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="grey"
                            class="mr-5 no-background-hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              tagNameSelected = tag.name;
                              deleteTagDialog = true;
                              handleNewCustomTagName = tag.name;
                            "
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("action.delete") }}</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" sm="7" lg="8" align-self="end">
                      <v-text-field
                        :placeholder="tag.name"
                        @input="$forceUpdate()"
                        v-model="tag.value"
                        hide-details="auto"
                        color="grey"
                        class="pt-0 my-0 body-2"
                        :rules="[rules.maxTagChars]"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
              <v-divider v-if="resetInputs" class="mt-5 mb-3" />
            </v-expansion-panel>
          </v-expansion-panels>

          <v-footer
            :class="`transparent ${resetInputs ? 'px-5' : 'px-0'} py-4`"
          >
            <v-btn
              v-if="resetInputs"
              color="grey"
              class="px-3 me-3"
              text
              x-large
              @click="resetFields"
              :disabled="canNotSaveOrCancel || loading"
            >
              {{ $t("action.clear") }}
            </v-btn>
            <v-btn
              v-else
              color="grey"
              class="px-3 me-3"
              text
              x-large
              @click="$emit('close')"
              :disabled="canNotSaveOrCancel || loading"
            >
              {{ $t("action.cancel") }}
            </v-btn>
            <v-btn
              v-if="isAdmin && !resetInputs && isGoogleUser"
              text
              large
              class="pr-6 pl-4 me-3"
              outlined
              @click="redirectToGoogleUserInWorkspace(googleID)"
            >
              <v-icon v-text="'mdi-google'" left color="red" class="mr-4" />
              {{ $t("common.editInGoogleDashboard") }}
            </v-btn>
            <v-spacer />
            <v-menu
              v-if="!isExternalPage"
              open-on-hover
              bottom
              left
              offset-overflow
              offset-y
              class="mx-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-large
                  color="accent"
                  class="px-6 ms-10 white--text"
                  :loading="loading"
                  :disabled="canNotSaveOrCancel || !valid"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon v-text="'mdi-content-save'" left />
                  {{ $t("action.save") }}
                </v-btn>
              </template>

              <v-list nav width="350" class="font-weight-medium">
                <v-list-item @click.stop="openSimpleDialog">
                  <v-list-item-icon class="mr-4">
                    <v-icon
                      v-text="'mdi-check-circle-outline'"
                      color="accent"
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      $t("action.saveOnly")
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      class="text-wrap font-weight-regular mt-1 caption"
                    >
                      {{ $t("common.saveOnlyDescription") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="isGoogleUser"
                  :disabled="!user.applied_signature_key"
                  @click.capture="openReaplyDialog"
                >
                  <v-list-item-icon class="mr-4">
                    <v-icon
                      v-text="'mdi-update'"
                      color="accent"
                      :disabled="!user.applied_signature_key"
                    />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("action.saveAndReapplySignature") }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      v-if="!user.applied_signature_key"
                      class="text-wrap font-weight-regular mt-1 caption"
                    >
                      <Alert dense color="orange" class="mb-0 caption">
                        {{ $t("common.userHasNoModelApplied") }}
                      </Alert>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle
                      v-else
                      class="text-wrap font-weight-regular mt-1 caption"
                    >
                      {{ $t("common.saveAndReapplyDescription") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              v-if="isExternalPage && !showPreviewAction"
              x-large
              color="accent"
              class="`white--text"
              :loading="loading"
              :disabled="canNotSaveOrCancel || !valid"
              @click="validate(true)"
            >
              <v-icon v-text="'mdi-content-save'" left />
              {{ $t("action.save") }}
            </v-btn>
            <v-btn
              v-if="isExternalPage && showPreviewAction"
              x-large
              color="accent"
              class="white--text"
              :loading="loading"
              @click="validate(true)"
            >
              {{ $t("action.continue") }}
              <v-icon v-text="'mdi-arrow-right'" right />
            </v-btn>
          </v-footer>
        </v-col>
      </v-row>
    </v-card>

    <!-- REAPLY -->
    <SimpleDialog
      id="reapply-model"
      :title="$t('userInformations.reapplyEmailSignature')"
      :show="showReapplyDialog"
      width="700"
      :showLoading="loading"
      :disabledAction="canNotSaveOrCancel || loading || !valid"
      showCancel
      :actionText="$t('action.confirm')"
      :closeText="$t('action.cancel')"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      :description="`${$t(
        'userInformations.reapplyConfirmationText'
      )} <strong>${this.user.name}<strong/>?`"
      @close="resetDataValue"
      @action="reapplyModels"
    >
      <template v-slot:body>
        <Alert :prominent="false" color="warning" dense :width="800">
          {{ $t("companyProfile.signatureModelsAlert") }}
        </Alert>

        <Alert :prominent="false" color="info" dense>
          <p>
            {{ $t("userInformations.saveInWorkspaceInfoText") }}
          </p>
          <ul
            v-for="data in tagsAllowedToEditInWorkspace"
            :key="data"
            class="py-1"
          >
            <li>{{ data }}</li>
          </ul>
        </Alert>
        <v-checkbox
          v-model="isUpdatingDataOnWorkspace"
          :label="$t('userInformations.saveInWorkspaceCheckboxText')"
          :disabled="!hasWorkspaceDataToUpdate"
        ></v-checkbox>
      </template>
    </SimpleDialog>

    <!-- CONFIRM -->
    <SimpleDialog
      :show="showSimple"
      :title="$t('userInformations.confirmInformationChanges')"
      :customButtom="true"
      outlined
      color="primary"
      button-class="white--text"
      dense
      showCancel
      :actionText="$t('action.confirm')"
      :showLoading="loading"
      :disabledAction="canNotSaveOrCancel || loading || !valid"
      :closeText="$t('action.cancel')"
      @close="resetDataValue"
      @action="validate(true)"
    >
      <template v-slot:body v-if="isGoogleUser">
        <Alert :prominent="false" color="info" dense>
          <p>
            {{ $t("userInformations.saveInWorkspaceInfoText") }}
          </p>
          <ul
            v-for="data in tagsAllowedToEditInWorkspace"
            :key="data"
            class="py-1"
          >
            <li>{{ data }}</li>
          </ul>
        </Alert>

        <v-checkbox
          v-model="isUpdatingDataOnWorkspace"
          :label="$t('userInformations.saveInWorkspaceCheckboxText')"
          :disabled="!hasWorkspaceDataToUpdate"
        ></v-checkbox>
      </template>
    </SimpleDialog>

    <HeaderDialog
      id="custom-tag-dialog"
      :title="$t('userInformations.newCustomTag')"
      color="primary"
      :show="showNewTagDialog"
      :loading="tagLoading"
      width="700"
      :disabledAction="disableCustomTagDialog"
      showCancel
      :closeText="$t('action.cancel')"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      @close="closeTagDialog"
      @action="addNewCustomTag"
    >
      <template v-slot:body v-if="showNewTagDialog">
        <v-row :class="$vuetify.breakpoint.smAndUp ? '' : 'd-flex flex-column'">
          <v-col>
            <v-text-field
              :rules="customTagRules"
              :label="$t('common.name')"
              v-model="handleNewCustomTagName"
              @update:error="tagError = $event"
              :error-messages="tagErroMessages"
              hide-details="auto"
            >
              <template v-slot:message="{ message }">{{ message }}</template>
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              disabled
              :label="$t('userInformations.representation')"
              :placeholder="customTag"
              persistent-placeholder
            />
          </v-col>
        </v-row>
      </template>
    </HeaderDialog>

    <HeaderDialog
      id="theweekend"
      :title="$t('userInformations.addImage')"
      color="primary"
      :show="showUploadImageDialog"
      :loading="uploadingImageLoading"
      width="700"
      :disabledAction="!file"
      showCancel
      :closeText="$t('action.cancel')"
      button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
      @close="closeImageUploadDialog"
      @action="uploadImage"
    >
      <template v-slot:body>
        <v-row class="flex-column justify-center">
          <v-avatar size="200" class="mb-4 mt-8 mx-auto">
            <img v-if="!imageURLPreview" :src="getPreviewTagImage" />
            <img v-else :src="imageURLPreview" />
          </v-avatar>

          <div class="mx-4">
            <v-file-input
              accept="image/*"
              :label="$t('userInformations.selectImage')"
              :hint="$t('userInformations.imageSavedOnDrive')"
              persistent-hint
              @change="handleFileUpload"
            ></v-file-input>
          </div>
        </v-row>
      </template>
    </HeaderDialog>

    <ConfirmationDialog
      :show="deleteTagDialog"
      :action-text="$t('userInformations.deleteCustomTag')"
      :loading="tagLoading"
      width="auto"
      @close="closeTagDialog"
      @ok="deleteCustomTag"
      :reversible="false"
      dense
      :message="`${$t(
        'userInformations.deleteCustomTagDescription'
      )} <strong>${tagNameSelected}</strong>?`"
    />
  </v-container>
</template>

<script>
import ConfirmationDialog from "@/components/general/ConfirmationDialog.vue";
import { editUserTagsInLocalStorage } from "@/helpers/services/getUsers";
import { changeDriveURL, isPublicLink } from "@/helpers/services/images";
import { redirectToGoogleUserInWorkspace } from "@/helpers/services/users";
import { MAX_TAGS_PER_USER } from "@/helpers/variables/backendConstants.js";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import {
  userDisabledTags,
  userEditableTags,
  userTags,
} from "@/helpers/variables/tags";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "UserTags",

  props: {
    user: { type: Object, required: true, default: () => {} },
    userToken: { type: String, required: false, default: "" },
    isExternalPage: { type: Boolean, required: false, default: false },
    resetInputs: { type: Boolean, default: false },
    showPreviewAction: { type: Boolean, default: false },
  },

  components: { ConfirmationDialog },

  data: () => ({
    panel: [0, 1, 2],
    valid: true,
    userDisabledTags,
    userEditableTags,
    tagsEdit: {},
    _tagsEdit: {},
    loading: false,
    rules: {
      maxPhone: (v) => v.length <= 20 || "Máximo de 20 caracteres",
      maxUserInfo: (v) => v.length <= 60 || "Máximo de 60 caracteres",
      maxTagChars: (v) =>
        String(v).length <= 1490 ||
        "O valor não pode ter mais que 1490 caracteres",
    },
    showReapplyDialog: false,
    showSimple: false,
    workspacePayload: [],
    hasWorkspaceDataToUpdate: false,
    isUpdatingDataOnWorkspace: false,
    emmitReaplyModel: false,
    customTagName: "",
    showNewTagDialog: false,
    tagErroMessages: [],
    tagNameSelected: "",
    tagError: false,
    deleteTagDialog: false,
    tagLoading: false,
    customTagsEdit: {},
    file: null,
    uploadingImageLoading: false,
    showUploadImageDialog: false,
    imageURLPreview: null,
    selectedTagImage: null,
    selectedTagImageURL: null,
    queryToken: null,
    photoLinkError: "",
  }),

  methods: {
    redirectToGoogleUserInWorkspace,

    ...mapActions(["setSignatureToUsers", "checkScopes"]),
    ...mapMutations([
      "setSnackBar",
      "setCurrentUser",
      "setUsersCustomTags",
      "setAcceptedDriveScopes",
    ]),

    resetFields() {
      this.getTagsUser();
      this.setCustomTagsEdit();
    },

    openUploadImageDialog(tag, tagImageURL) {
      this.selectedTagImage = tag;
      this.selectedTagImageURL = tagImageURL;
      this.showUploadImageDialog = true;
    },

    closeImageUploadDialog() {
      this.showUploadImageDialog = false;
      this.imageURLPreview = null;
      this.file = null;
      this.selectedTagImage = null;
      this.selectedTagImageURL = null;
    },

    handleFileUpload(imageFile) {
      if (imageFile) {
        this.imageURLPreview = URL.createObjectURL(imageFile);
      }
      this.file = imageFile;
    },

    uploadImage() {
      if (!this.file) return;

      this.uploadingImageLoading = true;
      const formData = new FormData();
      formData.append("image", this.file);
      formData.append("image_field_key", "sign_photo");

      const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/users`;

      const url = this.isExternalPage
        ? `${baseUrl}/upload-image`
        : `${baseUrl}/${this.user["key"]}/upload-image`;

      this.$axios
        .post(url, formData, this.auth)
        .then(({ data }) => {
          this.tagsEdit[this.selectedTagImage] = data.shared_drive_url;
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          this.setSnackBar({
            message: errorMessages["FAILED_TO_UPLOAD_IMAGE"],
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.uploadingImageLoading = false;
          this.closeImageUploadDialog();
        });
    },

    convertCustomTagName(tagName) {
      return tagName.toUpperCase().replace(/ /g, "_");
    },

    setCustomTagsEdit() {
      const customTagsEdit = this.usersCustomTagKeys.reduce((acc, tagKey) => {
        const customTag = this.usersCustomTags[tagKey];
        customTag.value = this.customTags[tagKey]?.value || "";
        acc[tagKey] = customTag;

        return acc;
      }, {});

      this.customTagsEdit = customTagsEdit;
    },

    deleteCustomTagInState() {
      const customTags = { ...this.usersCustomTags };
      const tagKey = this.convertCustomTagName(this.customTagName);

      delete customTags[tagKey];

      this.setUsersCustomTags(customTags);
    },

    addNewCustomTagInState(newCustomTag) {
      const tagKey = this.convertCustomTagName(newCustomTag.name);

      const newUsersCustomTags = {
        ...this.usersCustomTags,
        [tagKey]: newCustomTag,
      };

      this.setUsersCustomTags(newUsersCustomTags);
    },

    closeTagDialog() {
      this.tagError = false;
      this.customTagName = "";
      this.tagNameSelected = "";
      this.showNewTagDialog = false;
      this.deleteTagDialog = false;
      this.tagErroMessages = [];
    },

    deleteCustomTag() {
      this.tagLoading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/custom-tag?tag_key=${this.customTagKey}`;

      this.$axios
        .delete(url, this.auth)
        .then(() => {
          this.deleteCustomTagInState();
          this.setCustomTagsEdit();
          this.setSnackBar({
            message: successMessages.delete_tag_success,
            show: true,
          });
        })
        .catch(({ response: { data } }) => {
          this.setSnackBar({
            message: errorMessages[data.code],
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.closeTagDialog();
          this.tagLoading = false;
        });
    },

    async addNewCustomTag() {
      this.tagLoading = true;

      const url = `${process.env.VUE_APP_API_BASE_URL}/users/custom-tag`;

      const payload = {
        name: this.customTagName,
        tag: this.customTag,
      };

      this.$axios
        .post(url, payload, this.auth)
        .then(() => {
          this.addNewCustomTagInState(payload);
          this.setCustomTagsEdit();
          this.closeTagDialog();
          this.setSnackBar({
            message: successMessages.new_tag_success,
            show: true,
          });
        })
        .catch(({ response: { data } }) => {
          this.setSnackBar({
            message: errorMessages[data.code],
            show: true,
            color: "error",
          });

          this.tagErroMessages = [errorMessages[data.code]];
        })
        .finally(() => {
          this.tagLoading = false;
        });
    },

    resetDataValue() {
      this.showSimple = false;
      this.showReapplyDialog = false;
      this.isUpdatingDataOnWorkspace = false;
      this.loading = false;
      this.emmitReaplyModel = false;
      this.photoLinkError = "";
      this.assignWorkspacePayload();
    },

    async updateUserInWorkspace() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/conecta-sign/sync-users-in-workspace`;
      const payload = {
        emails: [this.user.email],
        workspace_data: this.workspacePayload,
      };

      return this.$axios.post(url, payload, this.auth);
    },

    openSimpleDialog() {
      this.assignWorkspacePayload();
      this.showSimple = true;
    },

    openReaplyDialog() {
      this.assignWorkspacePayload();
      this.showReapplyDialog = true;
    },

    assignWorkspacePayload() {
      this.workspacePayload = [];
      const google_workspace_editable_data = [
        "phone",
        "mobile",
        "departament",
        "position",
      ];

      const payload = this.createPayload();

      google_workspace_editable_data.map((workspaceData) => {
        if (workspaceData in payload) {
          this.workspacePayload.push(workspaceData);
        }
      });

      this.hasWorkspaceDataToUpdate = !!this.workspacePayload.length;
    },

    async validate(value = false) {
      if (this.$refs.form.validate() && value) {
        await this.checkPhotoLink();
        if (this.photoLinkError) {
          this.showSimple = false;
          return;
        }

        if (this.isExternalPage) {
          this.$emit("update", {
            id_user: this.googleID,
            editable_data: this.createPayload(),
          });
          return;
        }

        await this.save()
          .then(async () => {
            if (this.isUpdatingDataOnWorkspace) {
              await this.updateUserInWorkspace()
                .then(() => {
                  this.$emit("update", {
                    user: this.user,
                    reapplyModels: this.emmitReaplyModel,
                    hasSucceeded: true,
                  });
                })
                .catch((err) => {
                  console.error(err);
                  const code = err.response.data.code;
                  const message = `${successMessages.tags_update} <br> ${
                    errorMessages[code] || errorMessages["unknown"]
                  }`;
                  let color = "error";

                  if (code == "UNAUTHORIZED_UPDATE_USER_SCOPE") {
                    color = "warning";
                  }

                  this.$emit("update", {
                    user: this.user,
                    reapplyModels: this.emmitReaplyModel,
                    hasSucceeded: false,
                    message,
                    color,
                    timeout: 10000,
                  });
                });
            } else {
              this.$emit("update", {
                user: this.user,
                reapplyModels: this.emmitReaplyModel,
                hasSucceeded: true,
              });
            }
          })
          .finally(() => {
            this.resetDataValue();
          });
      }
    },

    getTagsUser() {
      this.tagsEdit = userTags
        .filter((tag) => tag != "photo")
        .reduce((a, e) => ((a[e] = this.user[e] || ""), a), {});
    },

    updateURL(tag) {
      this.tagsEdit[tag] = changeDriveURL(this.tagsEdit[tag]);
    },

    async checkPhotoLink() {
      const url = this.tagsEdit["sign_photo"];
      this.photoLinkError = "";
      if (!url) {
        return;
      }

      const isPublic = await isPublicLink(url);
      if (!isPublic) {
        this.photoLinkError = this.$t("errorMessages.photoLink");
        return;
      }
    },

    async reapplyModels() {
      await this.checkPhotoLink();
      if (this.photoLinkError) {
        this.showReapplyDialog = false;
        return;
      }

      const payload = {
        emails: [this.user.email],
        template_key: this.user.applied_signature_key,
      };

      let message = "";
      let code = "";
      let color = "success";

      await this.save()
        .then(async () => {
          await this.setSignatureToUsers(payload)
            .then(() => {
              this.emmitReaplyModel = true;
              message = successMessages.reapply_signature_to_users;
            })
            .catch((err) => {
              message = message + errorMessages.reapply_signature_error;
              color = "error";
              console.error(err);
            });

          if (this.isUpdatingDataOnWorkspace) {
            await this.updateUserInWorkspace()
              .then(() => {
                message =
                  message + "<br> " + successMessages.save_user_in_workspace;

                this.$emit("update", {
                  user: this.user,
                  hasSucceeded: false,
                  color,
                  message,
                  reapplyModels: this.emmitReaplyModel,
                });
              })
              .catch((err) => {
                color = "warning";
                code = err.response.data.code;

                message = `${message} <br> ${
                  errorMessages[code] || errorMessages["unknown"]
                }`;

                this.$emit("update", {
                  user: this.user,
                  reapplyModels: this.emmitReaplyModel,
                  hasSucceeded: false,
                  message,
                  color,
                  timeout: 10000,
                });
              });
          } else {
            this.$emit("update", {
              user: this.user,
              hasSucceeded: false,
              color,
              message,
              reapplyModels: this.emmitReaplyModel,
            });
          }
        })
        .finally(() => {
          this.resetDataValue();
        });
    },

    save() {
      this.loading = true;
      const url = `${process.env.VUE_APP_API_BASE_URL}/user/update`;
      const payload = {
        id_user: this.googleID,
        editable_data: this.createPayload(),
      };

      return this.$axios
        .post(url, payload, this.auth)
        .then(({ data }) => {
          editUserTagsInLocalStorage(this.user, this.tagsEdit);
          this.$emit("update:user", {
            id_google: this.googleID,
            ...data.user,
          });
          if (this.user.email === this.currentUser.email) {
            this.setCurrentUser(data.user);
          }
        })
        .catch((err) => {
          this.$emit("update", false);
          console.error(err);
        });
    },

    createPayload() {
      let payload = {};

      Object.keys(this.tagsEdit).forEach((tag) => {
        if (this.tagsEdit[tag] !== this.user[tag]) {
          payload[tag] = this.tagsEdit[tag];
        }
      });

      let customTagsEdited = false;

      const { custom_tags } = this.user;

      if (!custom_tags) {
        return payload;
      }
      this.usersCustomTagKeys.forEach((tag) => {
        if (this.customTagsEdit[tag].value != custom_tags[tag]?.value) {
          if (!payload.custom_tags) {
            payload.custom_tags = {};
          }

          payload.custom_tags[tag] = this.customTagsEdit[tag];

          customTagsEdited = true;
        }
      });

      if (!customTagsEdited) {
        delete payload.custom_tags;
      }

      return payload;
    },
  },

  computed: {
    ...mapGetters([
      "token",
      "isAdmin",
      "currentUser",
      "usersCustomTags",
      "usersCustomTagKeys",
      "hasAcceptedDriveScopes",
      "isCompanyMicrosoft",
    ]),

    isGoogleUser() {
      const oauth = this.user.oauth || "google";
      return oauth == "google";
    },

    getPreviewTagImage() {
      return this.selectedTagImageURL || require("@/assets/user-default.png");
    },

    customTags() {
      if (this.user.custom_tags) {
        return JSON.parse(JSON.stringify(this.user.custom_tags));
      }

      return {};
    },

    auth() {
      return {
        headers: {
          Authorization: this.userToken || this.token,
        },
      };
    },

    customTagKey() {
      return this.tagNameSelected.toUpperCase().replace(/ /g, "_");
    },

    disableCustomTagDialog() {
      return (
        this.tagError ||
        !this.customTagName ||
        this.customTagName === this.tagNameSelected
      );
    },

    handleNewCustomTagName: {
      get() {
        return this.customTagName;
      },
      set(name) {
        if (this.tagErroMessages) {
          this.tagErroMessages = [];
        }

        this.customTagName = name;
      },
    },

    customTag() {
      return `[*${this.customTagName}*]`.toUpperCase().replace(/ /g, "_");
    },

    tagLimitExceeded() {
      return this.usersCustomTagKeys.length >= MAX_TAGS_PER_USER;
    },

    disabledTagsNames() {
      return Object.keys(this.userDisabledTags);
    },

    canNotSaveOrCancel() {
      const payload = this.createPayload();
      return Object.keys(payload).length === 0;
    },

    photo() {
      return this.user ? this.user.photo : "";
    },

    googleID() {
      return this.user ? this.user.id_google : "";
    },

    contactTags() {
      const { phone, mobile, whatsapp_number } = this.userEditableTags;
      return Object.keys({
        phone,
        mobile,
        whatsapp_number,
      });
    },

    employeeTags() {
      const {
        departament,
        position,
        ramal,
        sign_photo,
        calendar_schedules_link,
      } = this.userEditableTags;

      return Object.keys({
        departament,
        position,
        ramal,
        sign_photo,
        calendar_schedules_link,
      });
    },
    userCustomTags() {
      const { user_custom_1, user_custom_2 } = this.userEditableTags;

      return Object.keys({
        user_custom_1,
        user_custom_2,
      });
    },

    customTagRules() {
      return [
        (v) => !!v || this.$t("textFieldRules.requiredField"),
        (v) =>
          /^[a-zA-Z0-9\s]+$/.test(v) ||
          this.$t("textFieldRules.specialCharacters"),
        (v) =>
          v.length <= 25 || `${this.$t("textFieldRules.fieldLessThan")} 25`,
        (v) =>
          v.trim().length > 0 ||
          this.$t("textFieldRules.cannotContainOnlySpaces"),
      ];
    },
    tagsAllowedToEditInWorkspace() {
      return [
        this.$t("userInformations.workPhone"),
        this.$t("userInformations.mobilePhone"),
        this.$t("userInformations.departament"),
        this.$t("userInformations.office"),
      ];
    },
  },

  created() {
    this.getTagsUser();
    if (this.$route.name === "UserTagsConfirmation") {
      this.queryToken = this.$route.query.token;
    }
  },

  watch: {
    user(value) {
      this.getTagsUser();

      if (value.custom_tags) {
        this.setCustomTagsEdit();
      }
    },

    tagsEdit: {
      handler() {
        if (
          this.tagsEdit &&
          this._tagsEdit &&
          JSON.stringify(this.tagsEdit) != JSON.stringify(this._tagsEdit)
        ) {
          this.$emit("preview", {
            editable_data: this.createPayload(),
          });
        }
        this._tagsEdit = { ...this.tagsEdit };
      },
      deep: true,
    },
  },

  beforeMount() {
    this.setCustomTagsEdit();

    if (!this.hasAcceptedDriveScopes && !this.isExternalPage) {
      const payload = {
        scopes: [INCREMENTAL_SCOPES.DRIVE],
        token: this.queryToken,
      };
      this.checkScopes(payload)
        .then(() => {
          this.setAcceptedDriveScopes(true);
        })
        .catch(() => {
          this.setAcceptedDriveScopes(false);
        });
    }
  },
};
</script>

<style scoped>
.avatar {
  cursor: pointer;
}
</style>
